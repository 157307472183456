export const CarouselModes = {
  DEFAULT: 'default',
  CENTERED: 'centered',
  PREVIEW: 'preview',
  CREATOR_PREVIEW: 'creator-preview',
  CREATOR_SOCIALS: 'creator-socials',
  PRODUCT: 'product'
};

export const FallbackImageURL =
  'https://raw.githubusercontent.com/koehlersimon/fallback/master/Resources/Public/Images/placeholder.jpg';

export const QuestionType = {
  Text: 'TEXT',
  Select: 'SELECT',
  Multiselect: 'MULTISELECT'
};

export const ImageTypes = {
  Main: 'Main',
  Spotlight: 'Spotlight',
  Discover: 'Discover'
};

export const BannerSlideType = {
  PRODUCT: 'product',
  CUSTOM: 'custom'
};

export const SupportedCreatorPlatforms = {
  TWITTER: 'twitter',
  INSTAGRAM: 'instagram',
  FACEBOOK: 'facebook',
  TIKTOK: 'tiktok',
  YOUTUBE: 'youtube'
};

export const ProductAvailability = {
  PreOrder: 'pre-order',
  Available: 'available',
  Funding: 'funding'
};

export const DefaultPageLimit = 12;

export const DefaultFilterOptions = [
  { text: 'All', value: 'all' },
  { text: 'A', value: 'a' },
  { text: 'B', value: 'b' },
  { text: 'C', value: 'c' },
  { text: 'D', value: 'd' },
  { text: 'E', value: 'e' },
  { text: 'F', value: 'f' },
  { text: 'G', value: 'g' },
  { text: 'H', value: 'h' },
  { text: 'I', value: 'i' },
  { text: 'J', value: 'j' },
  { text: 'K', value: 'k' },
  { text: 'L', value: 'l' },
  { text: 'M', value: 'm' },
  { text: 'N', value: 'n' },
  { text: 'O', value: 'o' },
  { text: 'P', value: 'p' },
  { text: 'Q', value: 'q' },
  { text: 'R', value: 'r' },
  { text: 'S', value: 's' },
  { text: 'T', value: 't' },
  { text: 'U', value: 'u' },
  { text: 'V', value: 'v' },
  { text: 'W', value: 'w' },
  { text: 'X', value: 'x' },
  { text: 'Y', value: 'y' },
  { text: 'Z', value: 'z' }
];

export const CreatorBackgroundColors = [
  '#10BCBE',
  '#0661CC',
  '#BE72EF',
  '#F96862',
  '#069CD6',
  '#A7FFF6',
  '#057173',
  '#E4572E'
];

export const ProductFormatAvailability = {
  Available: 'available',
  Unavailable: 'unavailable'
};

export const ProductFormatFulfilment = {
  Print: 'Print On Demand',
  Digital: 'Digital File'
};

export const DigitalFormatButtonTitle = 'Download';

export const WebApiCancelError = 'Abort due to cancellation of share.';

export const DefaultQuantityOptions = [
  { text: '1', value: '1' },
  { text: '2', value: '2' },
  { text: '3', value: '3' },
  { text: '4', value: '4' },
  { text: '5', value: '5' },
  { text: '6', value: '6' },
  { text: '7', value: '7' },
  { text: '8', value: '8' },
  { text: '9', value: '9' },
  { text: '10', value: '10' }
];

export const countryNames = {
  US: 'USA',
  GB: 'UK',
  CA: 'CAN',
  GO: 'Global',
  LATAM: 'LATAM',
  EU: 'EU'
};

export const EUCountry = {
  AT: 'AT', // Austria
  BE: 'BE', // Belgium
  BG: 'BG', // Bulgaria
  HR: 'HR', // Croatia
  CY: 'CY', // Cyprus
  CZ: 'CZ', // Czech Republic
  DK: 'DK', // Denmark
  EE: 'EE', // Estonia
  FI: 'FI', // Finland
  FR: 'FR', // France
  DE: 'DE', // Germany
  GR: 'GR', // Greece
  HU: 'HU', // Hungary
  IE: 'IE', // Ireland
  IT: 'IT', // Italy
  LV: 'LV', // Latvia
  LT: 'LT', // Lithuania
  LU: 'LU', // Luxembourg
  MT: 'MT', // Malta
  NL: 'NL', // Netherlands
  PL: 'PL', // Poland
  PT: 'PT', // Portugal
  RO: 'RO', // Romania
  SK: 'SK', // Slovakia
  SI: 'SI', // Slovenia
  ES: 'ES', // Spain
  SE: 'SE' // Sweden
};

export const LatinAmericanCountry = {
  AR: 'AR', // Argentina
  BO: 'BO', // Bolivia
  BR: 'BR', // Brazil
  CL: 'CL', // Chile
  CO: 'CO', // Colombia
  CR: 'CR', // Costa Rica
  DO: 'DO', // Dominican Republic
  EC: 'EC', // Ecuador
  SV: 'SV', // El Salvador
  GF: 'GF', // French Guiana
  GP: 'GP', // Guadeloupe
  GT: 'GT', // Guatemala
  HT: 'HT', // Haiti
  HN: 'HN', // Honduras
  MQ: 'MQ', // Martinique
  MX: 'MX', // Mexico
  NI: 'NI', // Nicaragua
  PA: 'PA', // Panama
  PY: 'PY', // Paraguay
  PE: 'PE', // Peru
  PR: 'PR', // Puerto Rico
  BL: 'BL', // Saint Barthélemy
  MF: 'MF', // Saint Martin
  UY: 'UY', // Uruguay
  VE: 'VE' // Venezuela
};
