export default {
  reCaptcha: '/api/check-token',
  creatorRequest: '/api/creator-request',
  products: '/api/hp-store/products',
  creators: '/api/hp-store/creators',
  stripe: '/api/stripe',
  config: '/api/config',
  checkout: '/api/checkout',
  shippingMethods: '/api/shipping-methods',
  recipes: '/api/hp-store/recipes',
  selections: '/api/hp-store/selections',
  contact: '/api/contact',
  statistics: '/api/hp-store/statistics',
  feedback: '/api/hp-store/feedback',
  payoutCodes: '/api/hp-store/payout-codes',
  sections: '/api/hp-store/sections',
  fundings: '/api/fundings'
};
